import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  get currentModal() {
    return this.modalTargets[this.modalIndex];
  }

  start() {
    this.modalIndex = 0;

    this.showDialog(this.currentModal);
  }

  finish() {
    this.hideDialog(this.currentModal);
    this.dispatch("finished", { modalIndex: this.modalIndex });
    this.modalIndex = 0;
  }

  next(event) {
    event.preventDefault();
    if (this.modalIndex + 1 >= this.modalTargets.length) {
      return this.finish();
    }

    this.hideDialog(this.currentModal);

    this.modalIndex++;
    this.showDialog(this.currentModal);
  }

  showDialog(target) {
    this.modalsControllerFor(target).show();
  }

  hideDialog(target) {
    this.modalsControllerFor(target).hide();
  }

  modalsControllerFor(target) {
    return this.application.getControllerForElementAndIdentifier(
      target,
      "modals"
    );
  }
}
