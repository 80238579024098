import { Controller } from "@hotwired/stimulus";

// Controller to toggle a class on a target, and remove it from
// any of the other targets if toggling on.
export default class extends Controller {
  static targets = ["toggleable", "default"];
  static values = {
    className: String,
  };

  toggle(event) {
    // Find if the child is already toggled on
    let toggledOn = event.currentTarget.classList.contains(this.classNameValue);

    // If toggledOn, toggle off
    if (toggledOn) {
      event.currentTarget.classList.remove(this.classNameValue);
    } else {
      // Else, toggle others off and toggle target on
      this.toggleableTargets.forEach((target) => {
        target.classList.remove(this.classNameValue);
      });
      event.currentTarget.classList.add(this.classNameValue);
    }
  }

  toggleDefault() {
    // Find if the child is already toggled on
    let toggledOn = this.defaultTarget.classList.contains(this.classNameValue);

    // If toggledOn, toggle off
    if (toggledOn) {
      this.defaultTarget.classList.remove(this.classNameValue);
    } else {
      // Else, toggle others off and toggle target on
      this.toggleableTargets.forEach((target) => {
        target.classList.remove(this.classNameValue);
      });
      this.defaultTarget.classList.add(this.classNameValue);
    }
  }

  removeAll() {
    this.toggleableTargets.forEach((target) => {
      target.classList.remove(this.classNameValue);
    });
  }
}
