// TODO: This is a short-term hack while we are spiking on
// scheduling availability.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(this.element).find("[data-toggle=popover]").popover({
      sanitize: false,
    });
  }
}
