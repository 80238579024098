import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    collapsed: {
      type: Boolean,
      default: false,
    },
  };

  static targets = ["button", "shownOnExpand"];

  toggle(event) {
    if (this.collapsedValue) {
      this.expand(event);
    } else {
      this.collapse();
    }
  }

  expand(event) {
    if (!this.collapsedValue) {
      return;
    }

    this.element.classList.remove("collapsed");
    this.collapsedValue = false;

    if (event.params.expandDefault) {
      $(this.shownOnExpandTargets).collapse?.("show");
    }
    this.dispatch("expand");
  }

  collapse() {
    if (this.collapsedValue) {
      return;
    }

    this.element.classList.add("collapsed");
    this.collapsedValue = true;
    $(this.element).find("[aria-expanded]").collapse?.("hide");
    this.dispatch("collapse");
  }
}
